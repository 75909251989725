import Router from 'vue-router'

const embedRouter = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/embed/register/:promotion?/:voucher?',
      name: 'embed-register-view'
    },
    {
      path: '/embed/login',
      name: 'embed-login-view'
    }
  ]
})

export default embedRouter
