import 'whatwg-fetch'
import embedRouter from './embedRouter'
import 'core-js/stable'
import 'regenerator-runtime/runtime'

const embedMatch = embedRouter.matcher.match(window.location.pathname)
const isEmbed = embedMatch.name !== null
let loadApp = () => {
  if (isEmbed) {
    return import(/* webpackChunkName: "embed" */ './Embed')
  } else {
    return import(/* webpackChunkName: "myfs-portal" */ './MyfsPortal')
  }
}
loadApp()
